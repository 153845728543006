import React from 'react';
import '../Styles/Style.css'

const Grain = () => {

  return (
    <div className="grain"></div>
  );
}

export default Grain;
